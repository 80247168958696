





































































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import ITRQ_DTO, {ITRQDocumentDTO} from "@/dto/request/itrq/ITRQ_DTO";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import {ITRQStep, ORDERED_STEPS} from "@/constants/request/ITRQStep";
import {ifValid} from "@/utils/ComponentUtils";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import ITRQSummary from "@/components/request/itrq/ITRQSummary.vue";
import {ITRQStatus} from "@/constants/request/RequestsStatuses";

const AppModule = namespace("App");

@Component({
  computed: {
    FileMetaDTO() {
      return FileMetaDTO
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    ITRQStep() {
      return ITRQStep
    },
    MaritalStatus() {
      return MaritalStatus
    },
    SelectOption() {
      return SelectOption
    },
    DocumentType() {
      return ITRQDocumentType
    }
  },
  components: {ITRQSummary, SingleFileHolder, PortalCheckbox, AutoComplete, PortalSelect, PortalInput, PortalCollapse}
})
export default class CustomerITRQ extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private q: ITRQ_DTO | null = null;

  private documents: Record<ITRQDocumentType, FileMetaDTO | null> = {} as Record<ITRQDocumentType, FileMetaDTO | null>;
  private irsLetters: Array<ITRQDocumentDTO> = [];
  private newIRSLetter: FileMetaDTO | null = null;

  mounted() {
    this.loadQuestionnaire();
  }

  loadQuestionnaire() {
    this.startLoading();
    IndividualTaxationService.getQuestionnaireByPublicId(this.id).then(
        ok => {
          this.initDocuments(ok.data.documents);
          this.q = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  initDocuments(docs: Array<ITRQDocumentDTO>) {
    this.irsLetters = [];
    docs.forEach(d => {
      if (d.type == ITRQDocumentType.IRS_LETTER) {
        this.irsLetters.push(d);
      } else {
        this.documents[d.type] = d.file;
      }
    });
  }

  get id() {
    return this.$route.params.id;
  }

  saveSpecificStep(step: ITRQStep){
    const actualStep = this.step;
    this.q!.step = step;
    ifValid(this, () => {
      this.q!.step = actualStep;
      this.save()?.then(
          () => {
            this.$void();
          }
      )
    })
  }


  save() {
    if (!this.q) {
      return;
    }
    this.q.language = this.$i18n.locale.toUpperCase();
    this.startLoading();
    return IndividualTaxationService.updateQuestionnaireByPublicId(this.id, this.q).then(
        ok => {
          this.stopLoading();
          return Promise.resolve(ok);
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
          return Promise.reject(err);
        }
    )
  }

  get isFillingStatus(): boolean {
    if(!this.q){
      return false;
    }
    return [ITRQStatus.SENT, ITRQStatus.OPENED, ITRQStatus.BEING_FILLED_OUT, ITRQStatus.PENDING_FOR_CONFIRMATION].includes(this.q.status);
  }

  get isPandaConfirmation(): boolean {
    return this.q?.status == ITRQStatus.PENDING_FOR_PANDADOC_CONFIRMATION;
  }

  get isConfirmed(): boolean {
    return this.q?.status == ITRQStatus.CONFIRMED;
  }

  get isPDStep(): boolean {
    return this.q?.step == ITRQStep.TAXPAYER_INFORMATION;
  }

  get isFDStep(): boolean {
    return this.q?.step == ITRQStep.FILING_STATUS;
  }

  get isMarried(): boolean {
    return this.q?.maritalStatus == MaritalStatus.MARRIED;
  }

  get isAddressStep(): boolean {
    return this.q?.step == ITRQStep.ADDRESS;
  }

  get isOccupationStep(): boolean {
    return this.q?.step == ITRQStep.OCCUPATION;
  }

  get isIncomeFormsStep(): boolean {
    return this.q?.step == ITRQStep.INCOME;
  }

  get isOnlineMerchantIncomeStep(): boolean {
    return this.q?.step == ITRQStep.ONLINE_MERCHANT_INCOME
  }

  get isAnyOnlineMerchantChecked(): boolean {
    const o = this.q!.onlineMerchantIncome;
    return o.zelle || o.airbnb || o.amazon || o.etsy || o.lyft || o.paypal || o.square || o.uber || o.venmo || o.vrbo || o.otherOnlineStore || o.otherPointOfSale || o.other;
  }

  get isNoOnlineIncomeChecked(): boolean {
    return this.q!.onlineMerchantIncome.noIncome;
  }

  get isAnyOnlineMerchantOrNoneChecked(): boolean {
    return this.isAnyOnlineMerchantChecked || this.isNoOnlineIncomeChecked;
  }

  get isDeductionsStep(): boolean {
    return this.q?.step == ITRQStep.DEDUCTIONS;
  }

  get isInsuranceStep(): boolean {
    return this.q?.step == ITRQStep.INSURANCE;
  }

  get isForeignBankAccountStep(): boolean {
    return this.q?.step == ITRQStep.FOREIGN_BANK_ACCOUNT;
  }

  get isCryptoStep(): boolean {
    return this.q?.step == ITRQStep.CRYPTO;
  }

  get isStockTradeStep(): boolean {
    return this.q?.step == ITRQStep.STOCK_TRADE;
  }

  get isIRSLettersStep(): boolean {
    return this.q?.step == ITRQStep.IRS_LETTERS;
  }

  get isBankAccountStep(): boolean {
    return this.q?.step == ITRQStep.BANK_ACCOUNT;
  }

  get isEstimatedTaxPaymentsStep(){
    return this.q?.step == ITRQStep.ESTIMATED_TAX_PAYMENTS;
  }

  get isSummaryStep(): boolean {
    return this.q?.step == ITRQStep.SUMMARY;
  }

  onClickNext() {
    ifValid(this, () => {
      this.setStep(this.getNextStep())();
    })
  }

  setStep(step: ITRQStep): () => void {
    return () => {
      this.q!.step = step;
      this.save();
    }
  }

  get step() {
    return this.q!.step;
  }

  getNextStep(): ITRQStep {
    const currentIndex = ORDERED_STEPS.indexOf(this.step);
    if (currentIndex == ORDERED_STEPS.length - 1) {
      return this.step;
    } else {
      return ORDERED_STEPS[currentIndex + 1]
    }
  }

  isStepPassed(targetStep: ITRQStep): boolean {
    return ORDERED_STEPS.indexOf(this.step) > ORDERED_STEPS.indexOf(targetStep);
  }

  isCurrentStepOrPassed(targetStep: ITRQStep): boolean {
    return ORDERED_STEPS.indexOf(this.step) >= ORDERED_STEPS.indexOf(targetStep);
  }

  saveDocument(type: ITRQDocumentType, file: FileMetaDTO) {
    this.startLoading();
    return IndividualTaxationService.saveDocumentByPublicId(this.id, type, file).then(
        ok => {
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  saveIRSLetter(file: FileMetaDTO){
    this.saveDocument(ITRQDocumentType.IRS_LETTER, file).then(
        ok => {
          this.newIRSLetter = null;
          this.save()?.then(
              ok => {
                this.loadQuestionnaire();
              }
          )
        }
    )
  }

  confirm(){
    this.q!.status = ITRQStatus.PENDING_FOR_PANDADOC_CONFIRMATION;
    this.save();
  }

  get maritalStatusOptions(): SelectOption[] {
    return Object.values(MaritalStatus).map(m => SelectOption.builder().title(this.$t(`profile.marital_statuses.${m}`) as string).value(m).build())
  }

}

